/* eslint-disable react/no-unknown-property */
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'

import { ButtonLink, ContainerCard, TitleLarge } from '../common/styles'
import MarkdownWrapper from '../components/MarkdownWrapper'
import breakpoint from '../constants/breakpoint'
import spacing from '../constants/spacing'
import Layout from '../layouts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BackButton = styled(ButtonLink)`
  display: inline-flex;
  margin-top: ${spacing.MEDIUM_PADDING};
  margin-bottom: ${spacing.MEDIUM_PADDING};
  margin-left: ${spacing.MEDIUM_PADDING};
`

const BackButtonText = styled.span`
  display: flex;
  align-items: center;
`

const Nav = styled.nav`
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 1000px;
`

const Card = styled(ContainerCard)`
  margin: 0px 35px 35px;
  width: 100%;
`

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoint.TABLET} {
    flex-direction: row;
    justify-content: center;
  }
`

const GiftButton = styled(ButtonLink)`
  margin-bottom: 21px;
  margin-right: 0px;

  @media ${breakpoint.TABLET} {
    margin-bottom: 0px;
    margin-right: 21px;
  }
`

const BackArrow = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z'
      fill='currentColor'
    />
  </svg>
)

const Prestation = ({
  data: { strapiService }
}: PageProps<Queries.PrestationQuery>) => (
  <Layout
    smallNavBar
    transparentBg={false}
    seo={{
      title: strapiService?.seo?.title,
      description: strapiService?.seo?.description
    }}
  >
    <Container>
      <Nav>
        <BackButton to='/prestations/' outline>
          <BackArrow />
          <BackButtonText>Retour aux prestations</BackButtonText>
        </BackButton>
      </Nav>
      <Card>
        <TitleLarge>{strapiService?.title}</TitleLarge>
        <MarkdownWrapper>
          {strapiService?.longDescription?.data?.longDescription!}
        </MarkdownWrapper>
        <ButtonsWrapper>
          {strapiService?.shopLink && (
            <GiftButton to={strapiService?.shopLink} large>
              Acheter un bon cadeau
            </GiftButton>
          )}
          <ButtonLink to='/contact/' large>
            Contact / Établir un devis
          </ButtonLink>
        </ButtonsWrapper>
      </Card>
    </Container>
  </Layout>
)

export default Prestation

export const query = graphql`
  query Prestation($id: String) {
    strapiService(id: { eq: $id }) {
      id
      title
      longDescription {
        data {
          longDescription
        }
      }
      shortDescription {
        data {
          shortDescription
        }
      }
      shopLink
      seo {
        description
        title
      }
    }
  }
`
